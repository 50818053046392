import React, {useEffect, useState} from 'react';
import {createConstant, getConstant, updateConstant, delConstantPrice, editPriceOne} from "../http/constantAPI"
import Modal from "../componets/modal/Model"
import {updateComponent} from "../http/componentAPI";
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import {getProduct} from "../http/productAPI";
const Price = () => {

    const api = new WooCommerceRestApi({
        url: "https://dari-shary.ru",
        consumerKey: "ck_43cc19745fb8acb7a740e9723e8e85047bf7e960",
        consumerSecret: "cs_9a9b6f01e72b2fb9b7c0fab1ea390c3e5b218a64",
        version: "wc/v3",
    });

    useEffect(()=> {
        getConstant().then(data => {
            setConstant(data)
        }).then()},[])

    useEffect(()=> {
        getProduct().then(data => {
            setProduct(data)
        }).then()},[])

    const [product, setProduct] = useState([]);
    const [constant, setConstant] = useState([]);
    const [modalConstantEdit, setModalConstantEdit] = useState(false)
    const [modalConstantCreate, setModalConstantCreate] = useState(false)
    const [modalConstantEditName, setModalConstantEditName] = useState(false)
    const [constantName, setConstantName] = useState({})
    const [input, setInput] = useState("");
    const [inputPrice, setInputPrice] = useState("")
    const [searchName, setSearchName] = useState("")
    const [searchNameModal, setSearchNameModal] = useState("")
    const [filter, setFilter] = useState(false)
    const [numDescendingState, setNumDescendingState] = useState(true)

    const [loaderContainer, setLoaderContainer] = useState("")
    const [loader, setLoader] = useState("")

    console.log(constant)

    const strAscending = [...constant].sort((a, b) => a.name.localeCompare(b.name))
    const numAscending = [...constant].sort((a, b) => a.price - b.price);
    const numDescending = [...constant].sort((a, b) => b.price - a.price);
    const delConstant = (constantName) => {
        const copyConstant = [...constant]
        let search = {};
        for (let i = 0; i < constant.length; i++){
            if (copyConstant[i].name === constantName){
                search = constant[i]
            }
        }
        const result = copyConstant.filter(c => c !== search )
        console.log(result)
        setConstant(result)
        console.log(search)
        delConstantPrice({constantName}).then(r=>console.log(r))
        window.location.reload()
    }
    const editConstant = (constantName) => {
        let search = {};
        for (let i = 0; i < constant.length; i++){
            if (constant[i].name === constantName){
                search = constant[i]
            }
        }
        setConstantName(search)
        setModalConstantEdit(true)
    }
    const editConstantPrice = (input, searchName) => {
        const l = "loader-container"
        const loader = "loader"
        setLoaderContainer(l)
        setLoader(loader)

        let search = [...constant];
        for (let i = 0; i < constant.length; i++){
            if (constant[i].name === constantName.name){
                search[i].price = Number(input)
            }
        }

        setConstant(search)
        setInput("")
        setModalConstantEdit(false)

        // console.log(input)
        // console.log(searchName)
        //let price = 0
        for (let i = 0; i < product.length; i++) {
            for (let j = 0; j < product[i].components.length; j++) {
                if (product[i].components[j].name === searchName) {
                    product[i].components[j].price = input
                    product[i].price = product[i].price - product[i].components[j].sum
                    product[i].components[j].sum = product[i].components[j].price * product[i].components[j].count
                    product[i].price = product[i].price + product[i].components[j].sum
                    console.log(product[i].name)
                    console.log(product[i].price)
                    const data = {
                        regular_price: String(product[i].price)
                    };
                    api.put(`products/${product[i].idWoo}`, data)
                        .then((response) => {
                            console.log(response.data)
                            setTimeout(() => {
                                setLoaderContainer("")
                                setLoader("")
                            }, 5000);
                        })
                        .catch((error) => {
                            alert(error.response.data.message)
                            console.log(error.response.data);
                        });
                    updateConstant({input, searchName}).then(r=>console.log(r))
                    updateComponent({input, searchName}).then(r=>console.log(r))
                }
            }
        }
    }


    const filterName =
        filter ?
            strAscending.filter(constant => {
            return constant.name.toLowerCase().includes(searchName.toLowerCase())}):
        numDescendingState ?
            numAscending.filter(constant => {
            return constant.name.toLowerCase().includes(searchName.toLowerCase())}):
            numDescending.filter(constant => {
            return constant.name.toLowerCase().includes(searchName.toLowerCase())})

    const filterNameModal = constant.filter(constant => {
        return constant.name.toLowerCase().includes(searchNameModal.toLowerCase())
    })
    const clickCount = () => {
        if (numDescendingState === false) {
            setNumDescendingState(true)
        }
        if (numDescendingState === true) {
            setNumDescendingState(false)
        }
    }

    const addConstant = (count, name) => {
        for (let i = 0; i < constant.length; i++){
            if (name === constant[i].name) {
                setInputPrice("")
                setSearchNameModal("")
                setModalConstantCreate(false)
                return;
            }
        }
        setInputPrice("")
        setSearchNameModal("")
        setModalConstantCreate(false)
        createConstant({count, name}).then(r => console.log(r))
        window.location.reload()
        console.log("Open")
        console.log(name)
        console.log(count)
    }

    const editPrice = () => {
        editPriceOne().then()
    }
    const editConstantName = (constantName) => {
        let search = {};
        for (let i = 0; i < constant.length; i++){
            if (constant[i].name === constantName){
                search = constant[i]
            }
        }
        setConstantName(search)
        setModalConstantEditName(true)
    }

    const editModalConstantEditName = (input, constantName) => {
        if (input !== constantName && input.length !== 0) {
            for (let i = 0; i < constant.length; i++){
                if (constant[i].name === constantName){
                    constant[i].name = input
                    console.log(constant[i].name)
                }
            }

            console.log("input")
            console.log(input)
            console.log("constantName")
            console.log(constantName)
            setModalConstantEditName(false)
        } else {
            setModalConstantEditName(false)
            return
        }
    }

    return (
        <>
            <div className={loaderContainer}>
                <div className={loader}></div>
            </div>
            <Modal active={modalConstantEdit} setActive={setModalConstantEdit}>
                 <h2>{constantName.name}</h2>
                 <h3>Текущая цена: {constantName.price} руб.</h3>
                 <input
                     disabled={false}
                     placeholder="Введите новую цену"
                     value={input}
                     onChange={(e) => {
                         setInput(e.target.value)
                     }}/>
                 <button onClick={()=>editConstantPrice(input,constantName.name)}>Изменить</button>
            </Modal>
            <Modal active={modalConstantCreate} setActive={setModalConstantCreate}>
                <h2>Создание компонента</h2>
                <div>Введите название:</div>
                <input
                    disabled={false}
                    placeholder="Название"
                    value={searchNameModal}
                    onChange={(e) => {
                        setSearchNameModal(e.target.value)
                    }}/>
                <ul className="autoComplete">
                    {
                        searchNameModal
                            ? filterNameModal.map((constant, index)=> {
                                return  (
                                    <li
                                        key={index}
                                        className="autoComplete__item">
                                        {constant.name}
                                    </li>
                                )
                            })
                            : null
                    }
                </ul>
                <div>Введите цену:</div>
                <input
                    disabled={false}
                    placeholder="Цена"
                    value={inputPrice}
                    onChange={(e) => {
                        setInputPrice(e.target.value)
                    }}/>
                <div>
                    <button onClick={()=>addConstant(inputPrice, searchNameModal)}>Создать</button>
                </div>
            </Modal>
            <Modal active={modalConstantEditName} setActive={setModalConstantEditName}>
                <h2>Редактирование имени</h2>
                <h4>Текужее именя: {constantName.name}</h4>
                <input
                value={input}
                onChange={(e) => {
                setInput(e.target.value)
            }}/>
                <div>
                    <button onClick={()=>editModalConstantEditName(input,constantName.name)}>Изменить</button>
                </div>
            </Modal>
            <div className="main">
                <h3>Прайс</h3>
                <input
                    className="inputCard1"
                    name="text"
                    placeholder={"Поиск "}
                    value={searchName}
                    onChange={(event) => setSearchName(event.target.value)}
                />
                <div>
                    <button onClick={()=>{
                        setModalConstantCreate(true)
                    }}>Создать</button>
                    {/*<button onClick={editPrice}>Исправить ошибки</button>*/}
                </div>
                <table className="tableItem">
                    <tbody>
                    <tr>
                        <td className="tableItem1" >
                            п/п
                        </td>
                        <td className="tableItem2">
                            <div onClick={()=>setFilter(true)}>
                               Название
                            </div>
                        </td>
                        <td className="tableItem3">
                            <div onClick={()=> {
                                 setFilter(false);
                                 clickCount()
                            }}>
                                Цена
                            </div>
                        </td>
                        <td className="tableItem4">
                            <div>
                                Управление
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                {filterName.map((constant, indexConstant)=> {
                    return (
                        <table key={indexConstant}>
                            <tbody>
                            <tr>
                                <td className="tableItem1" >
                                    {indexConstant + 1}
                                </td>
                                <td className="tableItem2">
                                    <div>
                                        {constant.name}
                                    </div>
                                </td>
                                <td className="tableItem3">
                                    <div>
                                        {constant.price} руб.
                                    </div>
                                </td>
                                <td className="tableItem4">
                                    {/*<button onClick={()=>delConstant(constant.name)}>Удалить</button>*/}
                                    <button onClick={()=>editConstant(constant.name)}>Ред. цену</button>
                                    {/*<button onClick={()=>editConstantName(constant.name)}>Ред. имя</button>*/}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    )})}
            </div>
        </>
    )
};

export default Price;
