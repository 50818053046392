import React from 'react';
import {Link, Outlet} from "react-router-dom";

const Layout = () => {
    return (
        <>
            <header>
                {/*<Link to="/store">БУКЕТЫ</Link>*/}
                <Link to="/price">ПРАЙС</Link>
                <Link to="/category">КАТЕГОРИИ</Link>
                <Link to="/tag">МЕТКИ</Link>
                <Link to="/description">ОПИСАНИЕ</Link>
                <Link to="/homePage">ПРОДУКТ</Link>
            </header>
            <div className="container">
                <div className="main">
                    <div>
                        <Outlet/>
                    </div>
                </div>
            </div>
            <footer>Панель управления v1.0 2023</footer>
        </>
    );
};

export {Layout};
