import React from "react";
import {Route, Routes} from "react-router-dom";


import "./app.css";
import "./bouquet.css"
import "./pading.css"

//import Store from "./pages/Store";
import HomePage from "./pages/HomePage";
import Price from "./pages/Price";
import Category from "./pages/Category"
import Tag from "./pages/Tag"
import Description from "./pages/Description"
import {Layout} from "./componets/Layout";


function App() {
  return (
    <Routes>
        <Route path="/" element={<Layout/>}>
            <Route index element={<Price/>}/>
            <Route path="homePage" element={<HomePage/>}/>
            {/*<Route path="store" element={<Store/>}/>*/}
            <Route path="price" element={<Price/>}/>
            <Route path="category" element={<Category/>}/>
            <Route path="tag" element={<Tag/>}/>
            <Route path="description" element={<Description/>}/>
        </Route>
    </Routes>
  )
}

export default App;
