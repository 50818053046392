import React, {useEffect, useState} from 'react';
import {createCategory, getCategory, updateCategory, delCategoryPrice} from "../http/categoryAPI"
import Modal from "../componets/modal/Model"
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
const Category = () => {
    const api = new WooCommerceRestApi({
        url: "https://dari-shary.ru",
        consumerKey: "ck_43cc19745fb8acb7a740e9723e8e85047bf7e960",
        consumerSecret: "cs_9a9b6f01e72b2fb9b7c0fab1ea390c3e5b218a64",
        version: "wc/v3",
    });

    useEffect(()=> {
        getCategory().then(data => {
            setCategory(data)
        }).then()},[])


    const [category, setCategory] = useState([]);
    const [modalCategoryEdit, setModalCategoryEdit] = useState(false)
    const [modalCategoryCreate, setModalCategoryCreate] = useState(false)
    const [categoryName, setCategoryName] = useState({})
    const [input, setInput] = useState("");
    const [inputPrice, setInputPrice] = useState("")
    const [searchName, setSearchName] = useState("")
    const [searchNameModal, setSearchNameModal] = useState("")
    const [filter, setFilter] = useState(false)
    const [numDescendingState, setNumDescendingState] = useState(true)
    const [loaderContainer, setLoaderContainer] = useState("")
    const [loader, setLoader] = useState("")

    const strAscending = [...category].sort((a, b) => a.label.localeCompare(b.label))
    const numAscending = [...category].sort((a, b) => a.price - b.price);
    const numDescending = [...category].sort((a, b) => b.price - a.price);
    const delCategory = (categoryName) => {
        const copyCategory = [...category]
        let search = {};
        for (let i = 0; i < category.length; i++){
            if (copyCategory[i].label === categoryName){
                search = category[i]
            }
        }
        const result = copyCategory.filter(c => c !== search )
        console.log(result)
        setCategory(result)
        console.log(search)
        delCategoryPrice({categoryName}).then(r=>console.log(r))
        window.location.reload()
    }
    const editCategory = (categoryName) => {
        let search = {};
        for (let i = 0; i < category.length; i++){
            if (category[i].label === categoryName){
                search = category[i]
            }
        }
        setCategoryName(search)
        setModalCategoryEdit(true)
    }
    const editCategoryPrice = (input, searchName, idWooCategory) => {
        const l = "loader-container"
        const loader = "loader"
        setLoaderContainer(l)
        setLoader(loader)

        const data = {
            name: input
        };

        api.put(`products/categories/${idWooCategory}`, data)
            .then((response) => {
                console.log("response.data")
                console.log(response.data)
                console.log("input")
                console.log(input)
                console.log("searchName")
                console.log(searchName)
                console.log("idWooCategory")
                console.log(idWooCategory)
                setInput("")
                setModalCategoryEdit(false)
                setLoaderContainer("")
                setLoader("")
                updateCategory({input, searchName}).then(r=>console.log(r))
                window.location.reload()

            })
            .catch((error) => {
                alert(error.response.data.message)
                console.log(error.response.data);
            });

    }


    const filterName =
        filter ?
            strAscending.filter(category => {
                return category.label.toLowerCase().includes(searchName.toLowerCase())}):
            numDescendingState ?
                numAscending.filter(category => {
                    return category.label.toLowerCase().includes(searchName.toLowerCase())}):
                numDescending.filter(category => {
                    return category.label.toLowerCase().includes(searchName.toLowerCase())})

    const filterNameModal = category.filter(category => {
        return category.label.toLowerCase().includes(searchNameModal.toLowerCase())
    })

    const activeLoaderMain = () => {
        const l = "loader-container"
        const loader = "loader"
        setLoaderContainer(l)
        setLoader(loader)
        setTimeout(() => {
            setLoaderContainer("")
            setLoader("")
        }, 3000);
    }
    const addCategory = (count, name) => {
        activeLoaderMain()
        for (let i = 0; i < category.length; i++){
            if (name === category[i].label) {
                setInputPrice("")
                setSearchNameModal("")
                setModalCategoryCreate(false)
                return;
            }
        }
        const data = {
            name: name,
        };
        api.post("products/categories", data)
            .then((response) => {
                const idWoo = response.data.id
                setLoaderContainer("")
                setLoader("")
                //console.log(response.data)
                setInputPrice("")
                setSearchNameModal("")
                setModalCategoryCreate(false)
                createCategory({count, name, idWoo}).then(r => console.log(r))
                setTimeout(() => {
                    window.location.reload()
                }, 3000);
            })
            .catch((error) => {
                alert(error.response.data.message)
                console.log(error.response.data);
            });
    }

    return (
        <>
            <div className={loaderContainer}>
                <div className={loader}></div>
            </div>
            <Modal active={modalCategoryEdit} setActive={setModalCategoryEdit}>
                <h2>Редактирование категории</h2>
                <h3>Текущее название: {categoryName.label}</h3>
                <input
                    disabled={false}
                    placeholder="Введите новое название"
                    value={input}
                    onChange={(e) => {
                        setInput(e.target.value)
                    }}/>
                <button onClick={()=>editCategoryPrice(input,categoryName.label,categoryName.idWooCategory)}>Изменить</button>
            </Modal>
            <Modal active={modalCategoryCreate} setActive={setModalCategoryCreate}>
                <h2>Создание категории</h2>
                <div>Введите название:</div>
                <input
                    disabled={false}
                    placeholder="Название"
                    value={searchNameModal}
                    onChange={(e) => {
                        setSearchNameModal(e.target.value)
                    }}/>
                <ul className="autoComplete">
                    {
                        searchNameModal
                            ? filterNameModal.map((category, index)=> {
                                return  (
                                    <li
                                        key={index}
                                        className="autoComplete__item">
                                        {category.label}
                                    </li>
                                )
                            })
                            : null
                    }
                </ul>
                <div>
                    <button onClick={()=>addCategory(inputPrice, searchNameModal)}>Создать</button>
                </div>
            </Modal>
            <div className="main">
                <h3>Категории</h3>
                <input
                    className="inputCard1"
                    name="text"
                    placeholder={"Поиск "}
                    value={searchName}
                    onChange={(event) => setSearchName(event.target.value)}
                />
                <div>
                    <button onClick={()=>{
                        setModalCategoryCreate(true)
                    }}>Создать</button>
                    {/*<button onClick={()=>addComponent()}>СоздатьDB</button>*/}
                </div>
                <table className="tableItem">
                    <tbody>
                    <tr>
                        <td className="tableItem1" >
                            п/п
                        </td>
                        <td className="tableItem2">
                            <div onClick={()=>setFilter(true)}>
                                Название
                            </div>
                        </td>
                        <td className="tableItem4">
                            <div>
                                Управление
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                {filterName.map((category, indexCategory)=> {
                    return (
                        <table key={indexCategory}>
                            <tbody>
                            <tr>
                                <td className="tableItem1" >
                                    {indexCategory + 1}
                                </td>
                                <td className="tableItem2">
                                    <div>
                                        {category.label}
                                    </div>
                                </td>
                                <td className="tableItem4">
                                    {/*<button onClick={()=>delCategory(category.label)}>Удалить</button>*/}
                                    <button onClick={()=>editCategory(category.label)}>Редактировать</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    )})}
            </div>
        </>
    )
};

export default Category;
