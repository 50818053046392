import React, {useEffect, useState} from 'react';
import {createDescription, getDescription, updateDescription, delDescriptionPrice} from "../http/descriptionAPI"
import Modal from "../componets/modal/Model"
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
const Description = () => {
    const api = new WooCommerceRestApi({
        url: "https://dari-shary.ru",
        consumerKey: "ck_43cc19745fb8acb7a740e9723e8e85047bf7e960",
        consumerSecret: "cs_9a9b6f01e72b2fb9b7c0fab1ea390c3e5b218a64",
        version: "wc/v3"
    });

    useEffect(()=> {
        getDescription().then(data => {
            setDescription(data)
        }).then()},[])


    const [description, setDescription] = useState([]);
    const [modalDescriptionEdit, setModalDescriptionEdit] = useState(false)
    const [modalDescriptionCreate, setModalDescriptionCreate] = useState(false)
    const [descriptionName, setDescriptionName] = useState({})
    const [input, setInput] = useState("");
    const [inputPrice, setInputPrice] = useState("")
    const [searchName, setSearchName] = useState("")
    const [searchNameModal, setSearchNameModal] = useState("")
    const [filter, setFilter] = useState(false)
    const [numDescendingState, setNumDescendingState] = useState(true)
    const [loaderContainer, setLoaderContainer] = useState("")
    const [loader, setLoader] = useState("")
    const [inputSelect, setInputSelect] = useState("Категория 1")

    const strAscending = [...description].sort((a, b) => a.name.localeCompare(b.name))
    const numAscending = [...description].sort((a, b) => a.price - b.price);
    const numDescending = [...description].sort((a, b) => b.price - a.price);
    const delDescription = (descriptionName) => {
        const copyDescription = [...description]
        let search = {};
        for (let i = 0; i < description.length; i++){
            if (copyDescription[i].name === descriptionName){
                search = description[i]
            }
        }
        const result = copyDescription.filter(c => c !== search )
        console.log(result)
        setDescription(result)
        console.log(search)
        delDescriptionPrice({descriptionName}).then(r=>console.log(r))
        setTimeout(() => {
            window.location.reload()
        }, 500);
    }
    const editDescription = (descriptionName) => {
        let search = {};
        for (let i = 0; i < description.length; i++){
            if (description[i].name === descriptionName){
                search = description[i]
            }
        }
        setDescriptionName(search)
        setModalDescriptionEdit(true)
    }
    const editDescriptionPrice = (input, searchName) => {
        let search = [...description];
        for (let i = 0; i < description.length; i++){
            if (description[i].name === descriptionName.name){
                search[i].price = Number(input)
            }
        }
        setDescription(search)
        setInput("")
        setModalDescriptionEdit(false)
        console.log(input)
        console.log(searchName)
        updateDescription({input, searchName}).then(r=>console.log(r))
        // updateComponent({input, searchName}).then(r=>console.log(r))
        window.location.reload()
    }


    const filterName =
        filter ?
            strAscending.filter(description => {
                return description.name.toLowerCase().includes(searchName.toLowerCase())}):
            numDescendingState ?
                numAscending.filter(description => {
                    return description.name.toLowerCase().includes(searchName.toLowerCase())}):
                numDescending.filter(description => {
                    return description.name.toLowerCase().includes(searchName.toLowerCase())})

    const filterNameModal = description.filter(description => {
        return description.name.toLowerCase().includes(searchNameModal.toLowerCase())
    })
    // const clickCount = () => {
    //     if (numDescendingState === false) {
    //         setNumDescendingState(true)
    //     }
    //     if (numDescendingState === true) {
    //         setNumDescendingState(false)
    //     }
    // }
    const activeLoaderMain = () => {
        const l = "loader-container"
        const loader = "loader"
        setLoaderContainer(l)
        setLoader(loader)
        setTimeout(() => {
            setLoaderContainer("")
            setLoader("")
        }, 3000);
    }
    const addDescription = (title, name) => {
        activeLoaderMain()
        console.log(title)
        console.log(name)
        for (let i = 0; i < description.length; i++){
            if (name === description[i].name) {
                setInputPrice("")
                setSearchNameModal("")
                setModalDescriptionCreate(false)
                return;
            }
        }
        createDescription({title, name}).then(r => console.log(r))
        setTimeout(() => {
            setLoaderContainer("")
            setLoader("")
            setInputPrice("")
            setSearchNameModal("")
            setModalDescriptionCreate(false)
            window.location.reload()
        }, 2500);
    }


    return (
        <>
            <div className={loaderContainer}>
                <div className={loader}></div>
            </div>
            <Modal active={modalDescriptionEdit} setActive={setModalDescriptionEdit}>
                <h2>Редактирование категории</h2>
                <h3>Текущее название: {descriptionName.name}</h3>
                <input
                    disabled={false}
                    placeholder="Введите новое название"
                    value={input}
                    onChange={(e) => {
                        setInput(e.target.value)
                    }}/>
                <button onClick={()=>editDescriptionPrice(input,descriptionName.name)}>Изменить</button>
            </Modal>
            <Modal active={modalDescriptionCreate} setActive={setModalDescriptionCreate}>
                <h2>Создание описание</h2>
                <div>Выберети категорию описания</div>
                <select key={1} onChange={(e)=>setInputSelect(e.target.value)}>
                    <option value={"Категория 1"}>Категория 1</option>
                    <option value={"Категория 2"}>Категория 2</option>
                    <option value={"Категория 3"}>Категория 3</option>
                    <option value={"Категория 4"}>Категория 4</option>
                </select>
                <div>Введите текс описания:</div>
                <textarea
                    disabled={false}
                    placeholder="Описание"
                    value={searchNameModal}
                    onChange={(e) => {
                        setSearchNameModal(e.target.value)
                    }}/>
                <ul className="autoComplete">
                    {
                        searchNameModal
                            ? filterNameModal.map((description, index)=> {
                                return  (
                                    <li
                                        key={index}
                                        className="autoComplete__item">
                                        {description.name}
                                    </li>
                                )
                            })
                            : null
                    }
                </ul>
                <div>
                    <button onClick={()=>addDescription(inputSelect, searchNameModal)}>Создать</button>
                </div>
            </Modal>
            <div className="main">
                <h3>Описание</h3>
                <input
                    className="inputCard1"
                    name="text"
                    placeholder={"Поиск "}
                    value={searchName}
                    onChange={(event) => setSearchName(event.target.value)}
                />
                <div>
                    <button onClick={()=>{
                        setModalDescriptionCreate(true)
                    }}>Создать</button>
                </div>
                <table className="tableItem">
                    <tbody>
                    <tr>
                        <td className="tableItem1" >
                            п/п
                        </td>
                        <td className="tableItem2">
                            <div onClick={()=>setFilter(true)}>
                                Название
                            </div>
                        </td>
                        <td className="tableItem3">
                            <div>
                                Тип
                            </div>
                        </td>
                        <td className="tableItem4">
                            <div>
                                Управление
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                {filterName.map((description, indexDescription)=> {
                    return (
                        <table key={indexDescription}>
                            <tbody>
                            <tr>
                                <td className="tableItem1" >
                                    {indexDescription + 1}
                                </td>
                                <td className="tableItem2">
                                    <div>
                                        {description.name}
                                    </div>
                                </td>
                                <td className="tableItem3">
                                    <div>
                                        {description.title}
                                    </div>
                                </td>
                                <td className="tableItem4">
                                    {/*<button onClick={()=>delDescription(description.name)}>Удалить</button>*/}
                                    {/*<button onClick={()=>editDescription(description.name)}>Редактировать</button>*/}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    )})}
            </div>
        </>
    )
};

export default Description;
