import {$host} from "../http/index";


export const createConstantDB = async (constant) => {
    for (let i = 0; i < constant.length; i++) {
        await $host.post('api/constant', constant[i])
        //console.log(constant[i])
       // return data
    }
}

export const getConstant = async (constant) => {
    const {data} = await $host.get('api/constant', constant)
    //console.log(data)
    return data
}

export const editPriceOne = async () => {
    console.log("click")
    const {data} = await $host.put('api/constant/editPriceOne')
    return data
}
export const createConstant = async (constant) => {
    //console.log(constant)
    const {data} = await $host.put('api/constant/newConstant', constant)
    //console.log(data)
    return data
}



// export const getConstantOne = async (constant) => {
//     const {data} = await $host.get('api/constant/one', constant)
//     console.log("Вывод constants")
//     console.log(constant)
//     return data
// }

export const updateConstant = async (constant) => {
    const {data} = await $host.put('api/constant/updateConstant', constant)
    //console.log(constant)
    return data
}


export const delConstantPrice = async (constant) => {
    const {data} = await $host.put('api/constant/delConstantPrice', constant)
    console.log(constant)
    return data
}
