import React, {useEffect, useState} from 'react';
import {createTag, getTag, updateTag, delTagPrice} from "../http/tagAPI"
import Modal from "../componets/modal/Model"
import {updateComponent} from "../http/componentAPI";
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import {createCategory, updateCategory} from "../http/categoryAPI";
const Tag = () => {
    const api = new WooCommerceRestApi({
        url: "https://dari-shary.ru",
        consumerKey: "ck_43cc19745fb8acb7a740e9723e8e85047bf7e960",
        consumerSecret: "cs_9a9b6f01e72b2fb9b7c0fab1ea390c3e5b218a64",
        version: "wc/v3"
    });

    useEffect(()=> {
        getTag().then(data => {
            setTag(data)
        }).then()},[])


    const [tag, setTag] = useState([]);
    const [modalTagEdit, setModalTagEdit] = useState(false)
    const [modalTagCreate, setModalTagCreate] = useState(false)
    const [tagName, setTagName] = useState({})
    const [input, setInput] = useState("");
    const [inputPrice, setInputPrice] = useState("")
    const [searchName, setSearchName] = useState("")
    const [searchNameModal, setSearchNameModal] = useState("")
    const [filter, setFilter] = useState(false)
    const [numDescendingState, setNumDescendingState] = useState(true)
    const [loaderContainer, setLoaderContainer] = useState("")
    const [loader, setLoader] = useState("")
    const [idWooTag, setIdWooTag] = useState("")
    const strAscending = [...tag].sort((a, b) => a.label.localeCompare(b.label))
    const numAscending = [...tag].sort((a, b) => a.price - b.price);
    const numDescending = [...tag].sort((a, b) => b.price - a.price);
    const delTag = (tagName) => {
        const copyTag = [...tag]
        let search = {};
        for (let i = 0; i < tag.length; i++){
            if (copyTag[i].label === tagName){
                search = tag[i]
            }
        }
        const result = copyTag.filter(c => c !== search )
        console.log(result)
        setTag(result)
        console.log(search)
        delTagPrice({tagName}).then(r=>console.log(r))
        window.location.reload()
    }
    const editTag = (tagName) => {
        let search = {};
        for (let i = 0; i < tag.length; i++){
            if (tag[i].label === tagName){
                search = tag[i]
            }
        }
        setTagName(search)
        setModalTagEdit(true)
    }
    const editTagPrice = (input, searchName, idWooTag) => {
        const l = "loader-container"
        const loader = "loader"
        setLoaderContainer(l)
        setLoader(loader)

        const data = {
            name: input
        };

        api.put(`products/tags/${idWooTag}`, data)
            .then((response) => {
                console.log("response.data")
                console.log(response.data)
                console.log("input")
                console.log(input)
                console.log("searchName")
                console.log(searchName)
                console.log("idWooTag")
                console.log(idWooTag)
                setInput("")
                setModalTagEdit(false)
                setLoaderContainer("")
                setLoader("")
                updateTag({input, searchName}).then(r=>console.log(r))
                window.location.reload()

            })
            .catch((error) => {
                alert(error.response.data.message)
                console.log(error.response.data);
            });

    }


    const filterName =
        filter ?
            strAscending.filter(tag => {
                return tag.label.toLowerCase().includes(searchName.toLowerCase())}):
            numDescendingState ?
                numAscending.filter(tag => {
                    return tag.label.toLowerCase().includes(searchName.toLowerCase())}):
                numDescending.filter(tag => {
                    return tag.label.toLowerCase().includes(searchName.toLowerCase())})

    const filterNameModal = tag.filter(tag => {
        return tag.label.toLowerCase().includes(searchNameModal.toLowerCase())
    })
    // const clickCount = () => {
    //     if (numDescendingState === false) {
    //         setNumDescendingState(true)
    //     }
    //     if (numDescendingState === true) {
    //         setNumDescendingState(false)
    //     }
    // }
    const activeLoaderMain = () => {
        const l = "loader-container"
        const loader = "loader"
        setLoaderContainer(l)
        setLoader(loader)
        setTimeout(() => {
            setLoaderContainer("")
            setLoader("")
        }, 3000);
    }
    const addTag = (count, name) => {
        activeLoaderMain()
        for (let i = 0; i < tag.length; i++){
            if (name === tag[i].label) {
                setInputPrice("")
                setSearchNameModal("")
                setModalTagCreate(false)
                return;
            }
        }
        const data = {
            name: name,
        };
        api.post("products/tags", data)
            .then((response) => {
                const idWoo = response.data.id
                setLoaderContainer("")
                setLoader("")
                //console.log(response.data)
                setInputPrice("")
                setSearchNameModal("")
                setModalTagCreate(false)
                createTag({count, name, idWoo}).then(r => console.log(r))
                setTimeout(() => {
                    window.location.reload()
                }, 2500);

            })
            .catch((error) => {
                alert(error.response.data.message)
                console.log(error.response.data);
            });

    }

    const addTags = () => {
        api.get("products/tags?per_page=100")
            .then((response) => {
                for (let i = 0; i < response.data.length; i++) {
                    console.log(i);
                    const res = {
                        idWooTag: response.data[i].id,
                        value: response.data[i].name,
                        label: response.data[i].name,
                        slug: response.data[i].slug,
                        description: ""
                    }
                    createTag(res).then()
                    console.log(res);
                }

            })
            .catch((error) => {
                console.log(error.response.data);
            });
    }


    return (
        <>
            <div className={loaderContainer}>
                <div className={loader}></div>
            </div>
            <Modal active={modalTagEdit} setActive={setModalTagEdit}>
                <h2>Редактирование категории</h2>
                <h3>Текущее название: {tagName.label}</h3>
                <input
                    disabled={false}
                    placeholder="Введите новое название"
                    value={input}
                    onChange={(e) => {
                        setInput(e.target.value)
                    }}/>
                <button onClick={()=>editTagPrice(input,tagName.label, tagName.idWooTag)}>Изменить</button>
            </Modal>
            <Modal active={modalTagCreate} setActive={setModalTagCreate}>
                <h2>Создание категории</h2>
                <div>Введите название:</div>
                <input
                    disabled={false}
                    placeholder="Название"
                    value={searchNameModal}
                    onChange={(e) => {
                        setSearchNameModal(e.target.value)
                    }}/>
                <ul className="autoComplete">
                    {
                        searchNameModal
                            ? filterNameModal.map((tag, index)=> {
                                return  (
                                    <li
                                        key={index}
                                        className="autoComplete__item">
                                        {tag.label}
                                    </li>
                                )
                            })
                            : null
                    }
                </ul>
                <div>
                    <button onClick={()=>addTag(inputPrice, searchNameModal)}>Создать</button>
                </div>
            </Modal>
            <div className="main">
                <h3>Метки</h3>
                <input
                    className="inputCard1"
                    name="text"
                    placeholder={"Поиск "}
                    value={searchName}
                    onChange={(event) => setSearchName(event.target.value)}
                />
                <div>
                    <button onClick={()=>{
                        setModalTagCreate(true)
                    }}>Создать</button>
                    {/*<button onClick={()=>addTags()}>СоздатьDB</button>*/}
                </div>
                <table className="tableItem">
                    <tbody>
                    <tr>
                        <td className="tableItem1" >
                            п/п
                        </td>
                        <td className="tableItem2">
                            <div onClick={()=>setFilter(true)}>
                                Название
                            </div>
                        </td>
                        <td className="tableItem4">
                            <div>
                                Управление
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                {filterName.map((tag, indexTag)=> {
                    return (
                        <table key={indexTag}>
                            <tbody>
                            <tr>
                                <td className="tableItem1" >
                                    {indexTag + 1}
                                </td>
                                <td className="tableItem2">
                                    <div>
                                        {tag.label}
                                    </div>
                                </td>
                                <td className="tableItem4">
                                    {/*<button onClick={()=>delTag(tag.label)}>Удалить</button>*/}
                                    <button onClick={()=>editTag(tag.label)}>Редактировать</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    )})}
            </div>
        </>
    )
};

export default Tag;
